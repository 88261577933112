import styled from 'styled-components';

export const Text14 = styled.div`
  font-family: 'Abitare Sans';
  font-size: 14px;
  color: #ffffff;
`;

export const Text16 = styled.div`
  font-family: 'Abitare Sans';
  font-size: 16px;
  color: #ffffff;
`;

export const Text18 = styled.div`
  font-family: 'Abitare Sans';
  font-size: 18px;
  line-height: 29px;
  font-weight: 300;
  letter-spacing: -0.019em;
  color: #ffffff;
`;

export const Text20 = styled.div`
  font-family: 'Abitare Sans';
  font-size: 20px;
  line-height: 31px;
  letter-spacing: -0.019em;
  color: #ffffff;
`;

export const Title24 = styled.h3`
  font-size: 24px;
  font-weight: 100;
  color: #ffffff;
`;

export const Title26 = styled.h3`
  font-size: 26px;
  font-weight: 100;
  color: #ffffff;
`;

export const Title35 = styled.h3`
  font-size: 35px;
  font-weight: 100;
  color: #ffffff;
  margin-bottom: 35px;
`;

export const Title42 = styled.h3`
  font-size: 42px;
  font-weight: 100;
  color: #ffffff;
  margin-bottom: 35px;
`;
