import React from 'react';
import styled from 'styled-components';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';

import * as StyleVars from './../cssVariables';

const WrappedInput = styled.form`
  position: relative;
  background: #c5c7ca;
  display: flex;
  margin: 20px 0;

  input {
    font-size: 18px;
    background: transparent;
    border: 0;
    display: flex;
    flex: 1 0;
    padding-left: 20px;
  }
`;

const Button = styled.button`
  margin: 20px 20px 20px 0;
  background: #688e7f;
  border: 0;
  color: white;
  padding: 15px 30px;
  font-weight: 100;

  @media (${StyleVars.mediaMobile}) {
    margin: 0;
    padding: 15px 0;
    width: 100%;
  }
`;

const FormContainer = styled.div`
  & > div > div > div {
    margin: 20px auto;
  }
`;

const RECAPTCHA_KEY = `6LfJbAoaAAAAALieDSoVBquJbZP_LqdjIs4lKOKp`;
const FORM_NAME = 'contacts';

export default class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this._recaptchaRef = React.createRef();
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') loadReCaptcha(RECAPTCHA_KEY);
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  render() {
    return (
      <FormContainer>
        <WrappedInput
          data-netlify="true"
          id="signupForm"
          name={FORM_NAME}
          method="post"
        >
          <input type="hidden" name="form-name" value={FORM_NAME} />
          <input placeholder="your@email.com" name="email" type="email" />
          <Button type="submit">Notify me!</Button>
        </WrappedInput>

        <ReCaptcha
          ref={this._recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          action={FORM_NAME}
        />
      </FormContainer>
    );
  }
}
