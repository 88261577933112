import styled from 'styled-components';
import * as StyleVars from './../cssVariables';

export default styled.div`
  width: 889px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media(${StyleVars.mediaTabletLarge}) {
    width: 90%;
  }

  @media(${StyleVars.mediaTablet}) {
    width: 95%;
  }

  @media(${StyleVars.mediaMobileLarge}) {
    width: 100%;
  }

  @media(${StyleVars.mediaMobile}) {
    width: 90%;
  }
`