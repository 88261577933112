import React from "react";

const LogoPlanckEzText = (props) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 97 22">
        <path
          stroke="#fff"
          strokeWidth=".5"
          d="M59.8171 21.577L59.8171 1.03161L96.7407 1.03161L96.7406 21.577L59.8171 21.577Z"
        />
        <path
          fill="#fff"
          d="M73.0116 7.33512L73.0116 10.1507L76.5669 10.1507L76.5669 11.6041L73.0116 11.6041L73.0116 14.4759L77.5225 14.4759L77.5225 15.9855L71.1146 15.9855L71.1146 5.83255L77.5225 5.83255L77.5225 7.33513L73.0116 7.33512Z"
        />
        <path
          fill="#fff"
          d="M86.1956 6.52767C86.1956 6.74299 86.1347 6.93959 86.0129 7.11747L80.7995 14.4759L86.0762 14.4759V15.9855L78.3473 15.9855V15.2412C78.3473 15.1476 78.3637 15.0586 78.3965 14.9744C78.4293 14.8854 78.4714 14.8035 78.523 14.7286L83.7505 7.33513L78.6775 7.33513L78.6775 5.83255L86.1956 5.83255V6.52767Z"
        />
        <path
          fill="#fff"
          d="M2.97208 11.1402C3.36086 11.1402 3.7028 11.0887 3.9979 10.9857C4.29769 10.8828 4.54829 10.74 4.74971 10.5574C4.95581 10.3702 5.11038 10.1479 5.21344 9.8904C5.31649 9.63295 5.36801 9.34976 5.36801 9.04082C5.36801 8.39953 5.16894 7.89867 4.77079 7.53824C4.37732 7.17781 3.77775 6.99759 2.97208 6.99759L1.35606 6.99759L1.35606 11.1402L2.97208 11.1402ZM2.97208 5.92333C3.60912 5.92333 4.16185 5.99822 4.63026 6.14801C5.10336 6.29312 5.49448 6.50142 5.80364 6.77291C6.11279 7.0444 6.34231 7.37207 6.4922 7.7559C6.64678 8.13974 6.72407 8.56804 6.72407 9.04082C6.72407 9.50891 6.64209 9.93721 6.47815 10.3257C6.31421 10.7142 6.07297 11.0489 5.75445 11.3298C5.44062 11.6106 5.04949 11.8306 4.58108 11.9898C4.11735 12.1443 3.58102 12.2215 2.97208 12.2215L1.35606 12.2215L1.35605 15.985L-1.22171e-09 15.985L1.76042e-06 5.92332L2.97208 5.92333Z"
        />
        <path
          fill="#fff"
          d="M14.152 14.8405L14.152 15.985L8.43966 15.985L8.43967 5.92333L9.79572 5.92333L9.79572 14.8405L14.152 14.8405Z"
        />
        <path
          fill="#fff"
          d="M21.0049 12.2496L19.424 8.15612C19.3772 8.03442 19.328 7.89399 19.2765 7.73484C19.2249 7.57101 19.1758 7.39782 19.1289 7.21526C19.0306 7.59441 18.9298 7.91038 18.8268 8.16315L17.2459 12.2496L21.0049 12.2496ZM23.8435 15.985L22.7896 15.985C22.6678 15.985 22.5694 15.9545 22.4945 15.8937C22.4195 15.8328 22.3633 15.7556 22.3258 15.662L21.3843 13.2326L16.8665 13.2326L15.925 15.662C15.8969 15.7462 15.843 15.8211 15.7634 15.8867C15.6837 15.9522 15.5854 15.985 15.4683 15.985L14.4143 15.985L18.4404 5.92333L19.8175 5.92333L23.8435 15.985Z"
        />
        <path
          fill="#fff"
          d="M33.3254 5.92333L33.3254 15.985L32.6439 15.985C32.5362 15.985 32.4448 15.9662 32.3699 15.9288C32.2996 15.8913 32.2294 15.8281 32.1591 15.7392L26.3344 8.15613C26.3438 8.27315 26.3508 8.38783 26.3555 8.50017C26.3602 8.61251 26.3625 8.71784 26.3625 8.81613L26.3625 15.985L25.168 15.985L25.168 5.92333L25.8707 5.92333C25.9316 5.92333 25.9831 5.92801 26.0252 5.93737C26.0674 5.94205 26.1049 5.95376 26.1377 5.97248C26.1704 5.98652 26.2032 6.00993 26.236 6.04269C26.2688 6.07078 26.3039 6.10823 26.3414 6.15503L32.1661 13.7311C32.1568 13.6094 32.1474 13.4924 32.138 13.38C32.1333 13.263 32.131 13.153 32.131 13.05L32.131 5.92333L33.3254 5.92333Z"
        />
        <path
          fill="#fff"
          d="M42.9678 13.9066C43.0427 13.9066 43.1083 13.9371 43.1645 13.9979L43.7055 14.5807C43.2933 15.0581 42.7921 15.4303 42.2019 15.6971C41.6164 15.9639 40.9068 16.0973 40.073 16.0973C39.3516 16.0973 38.6959 15.9733 38.1057 15.7252C37.5155 15.4724 37.0119 15.1213 36.595 14.672C36.1782 14.2179 35.8549 13.6749 35.6254 13.043C35.3959 12.4111 35.2811 11.716 35.2811 10.9577C35.2811 10.1993 35.4006 9.50423 35.6395 8.87231C35.8784 8.24038 36.2133 7.6974 36.6442 7.24335C37.0799 6.7893 37.5998 6.43823 38.204 6.19014C38.8083 5.93737 39.4758 5.81099 40.2065 5.81099C40.9232 5.81099 41.5555 5.92567 42.1036 6.15504C42.6516 6.3844 43.1341 6.69568 43.551 7.08888L43.1013 7.71378C43.0732 7.76059 43.0357 7.80038 42.9889 7.83315C42.9467 7.86123 42.8882 7.87527 42.8132 7.87527C42.7289 7.87527 42.6259 7.8308 42.5041 7.74187C42.3823 7.64825 42.223 7.54527 42.0263 7.43293C41.8296 7.32058 41.5836 7.21995 41.2885 7.13101C40.9934 7.03739 40.6304 6.99058 40.1995 6.99058C39.6795 6.99058 39.2041 7.08186 38.7732 7.26441C38.3422 7.44229 37.9698 7.70208 37.656 8.04379C37.3468 8.38549 37.1056 8.80209 36.9323 9.29359C36.759 9.78509 36.6723 10.3398 36.6723 10.9577C36.6723 11.5849 36.7613 12.1443 36.9393 12.6358C37.122 13.1273 37.3679 13.5439 37.6771 13.8856C37.9909 14.2226 38.3586 14.48 38.7802 14.6579C39.2064 14.8358 39.6655 14.9247 40.1573 14.9247C40.4571 14.9247 40.7264 14.9083 40.9653 14.8756C41.2089 14.8381 41.4314 14.782 41.6328 14.7071C41.8389 14.6322 42.0286 14.5386 42.2019 14.4262C42.3799 14.3092 42.5556 14.1711 42.7289 14.012C42.8085 13.9417 42.8882 13.9066 42.9678 13.9066Z"
        />
        <path
          fill="#fff"
          d="M46.6421 10.3328L47.155 10.3328C47.333 10.3328 47.4758 10.3117 47.5836 10.2696C47.6913 10.2228 47.7897 10.1455 47.8787 10.0379L51.2302 6.24632C51.3238 6.12461 51.4222 6.04036 51.5253 5.99355C51.6283 5.94674 51.7548 5.92333 51.9047 5.92333L53.057 5.92333L49.2207 10.2555C49.127 10.3679 49.0356 10.4615 48.9466 10.5364C48.8623 10.6066 48.771 10.6651 48.6726 10.7119C48.7991 10.754 48.9139 10.8172 49.0169 10.9015C49.12 10.9811 49.2207 11.0864 49.319 11.2174L53.324 15.985L52.1436 15.985C52.0546 15.985 51.9773 15.9803 51.9117 15.9709C51.8508 15.9569 51.7969 15.9382 51.7501 15.9147C51.7033 15.8867 51.6611 15.8562 51.6236 15.8235C51.5908 15.786 51.558 15.7439 51.5253 15.6971L48.0543 11.7019C47.9606 11.5849 47.8599 11.503 47.7522 11.4562C47.6445 11.4047 47.4805 11.3789 47.2604 11.3789L46.6421 11.3789L46.6421 15.985L45.286 15.985L45.286 5.92333L46.6421 5.92333L46.6421 10.3328Z"
        />
      </svg>
    </>
  );
};

export default LogoPlanckEzText;
