import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import * as StyleVars from './../cssVariables';
import MenuIcon from './MobileMenu';

const MainNavigationContainer = styled.nav`
  background: ${props => props.theme.colors.mainNavbarBg};
  height: 50px;
  width: 100%;
  position: relative;
  z-index: 5;

  @media (${StyleVars.mediaTablet}) {
    height: auto;
  }
`;

const Container = styled.div`
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  box-sizing: border-box;
`;

const Menu = styled.div`
  margin: 0;
  display: flex;
  align-items: center;

  @media (${StyleVars.mediaTablet}) {
    display: none;
  }
`;

const MenuItem = styled.a`
  font-family: 'Inter';
  font-weight: normal;
  text-decoration: none;
  position: relative;
  padding: 0 20px;
  color: ${props => props.theme.colors.navItemColor};
  text-transform: uppercase;
  font-size: 13px;

  &.-is-active,
  &:hover,
  &[aria-current='page'] {
    color: ${props => props.theme.colors.navItemActiveColor};
  }

  &:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: white;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const LogoContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function MainNavigation(props) {
  const MenuItems = [
    {
      label: 'Moonlander',
      as: 'a',
      link: 'https://zsa.io/moonlander',
    },
    {
      label: 'ErgoDox EZ',
      as: 'a',
      link: 'https://ergodox-ez.com/',
    },
    {
      label: 'Planck EZ',
      as: 'a',
      link: 'https://www.zsa.io/planck',
    },
    {
      label: 'Habit/Stick',
      as: Link,
      link: '/',
    },
    {
      label: 'Blog',
      as: 'a',
      link: '#',
    },
    {
      label: 'Contact',
      as: 'a',
      link: 'mailto:contact@zsa.io',
    },
  ];

  const renderLink = (item, i) => {
    if (item.as === 'a')
      return (
        <MenuItem key={i} as="a" href={item.link}>
          {item.label}
        </MenuItem>
      );

    return (
      <MenuItem key={i} as={Link} to={item.link}>
        {item.label}
      </MenuItem>
    );
  };

  return (
    <MainNavigationContainer>
      <Container>
        <LogoContainer as={Link} to="/">
          <img src="/assets/logo.svg" alt="Habit Sticks Logo" />
        </LogoContainer>
        <Menu>{MenuItems.map((item, i) => renderLink(item, i))}</Menu>

        <MenuIcon />
      </Container>
    </MainNavigationContainer>
  );
}
