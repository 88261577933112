import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Theme from "./Theme";
import GlobalStyles from "../styles";

import MainNavigation from './MainNavigation';
import Footer from './Footer';

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (<StaticQuery query={graphql`query {
      site {
        siteMetadata {
          title
        }
      }
    }`} render={(data) => <Theme>
        <GlobalStyles />
        <MainNavigation />
        {children}
        <Footer />
      </Theme>} />)
  }
}

export default Layout;