import React, { createElement } from "react";
import styled from "styled-components";
import * as StyledVars from "../cssVariables";
import { Text14 } from "./Typography";

const Item = styled.div`
  background: ${(props) => props.background};
  display: grid;
  align-items: center;
  grid-template-columns: 113px 1fr;
  padding: 17px 24px 17px 0;
  text-decoration: none;
  &.active {
    background: radial-gradient(
        97.7% 403.1% at 10.75% 61.72%,
        #bfe4fa 0%,
        rgba(60, 92, 110, 0.18) 100%
      ),
      linear-gradient(0deg, rgba(34, 38, 43, 0.5), rgba(34, 38, 43, 0.5)),
      #3f6083;
  }

  @media (${StyledVars.mediaMobileSmall}) {
    grid-template-columns: 80px 1fr;
  }
`;

const Display = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    flex: 1 1 auto;
    max-width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  max-width: 98px;
  margin-bottom: 6px;
`;

const Text = styled(Text14)`
  display: block;
  line-height: 96%;
  color: #ffffff;
`;

const ZsaMenuItem = ({ item }) => (
  <Item
    background={item.background}
    {...item.attrs}
  >
    <Display width={item.logoWidth}>{createElement(item.logo, null)}</Display>
    <Content>
      <Title>{createElement(item.title, null)}</Title>
      <Text>{item.text}</Text>
    </Content>
  </Item>
);

export default ZsaMenuItem;
