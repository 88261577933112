import React, { PureComponent } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logo from "./zsaLogo";
import AnimateHeight from "react-animate-height";
import LogoErgodoxEz from "./Icons/LogoErgodoxEz";
import LogoErgodoxEzText from "./Icons/LogoErgodoxEzText";
import LogoPlanckEz from "./Icons/LogoPlanckEz";
import LogoPlanckEzText from "./Icons/LogoPlanckEzText";
import LogoMoonlanderText from "./Icons/LogoMoonlanderText";
import imageMoonlanderShip from "../images/moonlander-ship.png";
import ZsaMenuItem from "./ZsaMenuItem";

const Wrapper = styled.div`
  padding: 10px 24px 10px 10px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoMoonlander = styled.span`
  display: inline-block;
  width: 78px;
  height: 51px;
  background: url(${imageMoonlanderShip}) center center no-repeat;
  background-size: contain;
`;

const items = [
  {
    logo: LogoMoonlander,
    title: LogoMoonlanderText,
    text: "Next-gen Ergonomics",
    active:
      "radial-gradient(97.7% 403.1% at 10.75% 61.72%, #BFE4FA 0%, rgba(60, 92, 110, 0.18) 100%), linear-gradient(0deg, rgba(34, 38, 43, 0.5), rgba(34, 38, 43, 0.5)), #3F6083;",
    attrs: {
      to: "https://zsa.io/moonlander",
      as: Link,
      activeClassName: "active",
      partiallyActive: true
    },
    background:
      "radial-gradient(91% 375.46% at 17.45% 61.72%, rgba(111, 246, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(34, 38, 43, 0.5), rgba(34, 38, 43, 0.5)), #3F6083;"
  },
  {
    logo: LogoErgodoxEz,
    logoWidth: 56,
    title: LogoErgodoxEzText,
    text: "Custom Comfort",
    attrs: {
      href: "https://ergodox-ez.com/",
      target: "_blank",
      rel: "noopener",
      as: "a"
    },
    background:
      "radial-gradient(91% 375.46% at 17.45% 61.72%, rgba(255, 222, 137, 0.32) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(34, 38, 43, 0.5), rgba(34, 38, 43, 0.5)), #AA8B5D;"
  },
  {
    logo: LogoPlanckEz,
    logoWidth: 56,
    title: LogoPlanckEzText,
    text: "Portable Power",
    attrs: {
      href: "/planck",
      as: Link,
      activeClassName: "active"
    },
    background:
      "radial-gradient(91% 375.46% at 17.45% 61.72%, rgba(252, 255, 111, 0.16) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(34, 38, 43, 0.5), rgba(34, 38, 43, 0.5)), #AC7777;"
  }
];

class ZsaProductsMenu extends PureComponent {
  render() {
    return (
      <div>
        <Wrapper>
          <Logo cy="zsa-products-logo" />
        </Wrapper>
        <div>
          <AnimateHeight height="auto" duration={400}>
            {items.map((item, key) => {
              return <ZsaMenuItem key={`item-${key}`} item={item} />;
            })}
          </AnimateHeight>
        </div>
      </div>
    );
  }
}

export default ZsaProductsMenu;
