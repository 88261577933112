import React from 'react';
import styled from 'styled-components';
import * as StyleVars from './../cssVariables';
import { Text16 } from './Typography';

const HeroImg = styled.img`
  margin: 0 auto;
  width: 250px;

  @media (${StyleVars.mediaMobileLarge}) {
    width: 80%;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
`;

const Separator = styled.span`
  margin: 0 10px;
  display: inline-block;
`;

const CustomLink = styled.a`
  color: #ffe5a3;
`;

const FooterContainer = styled.div`
  margin-top: 100px;
  padding-bottom: 50px;
`;

export default function Footer(props) {
  return (
    <FooterContainer>
      <Content>
        <HeroImg src="/assets/logo-plain.svg" alt="Banner Logo" />
        <Text16>
          <span>
            &copy;{' '}
            <CustomLink href="https://zsa.io">ZSA Technology Labs</CustomLink>{' '}
            2020
          </span>{' '}
          <Separator>|</Separator>{' '}
          <CustomLink href="mailto:contact@zsa.io">Contact Us</CustomLink>
        </Text16>
      </Content>
    </FooterContainer>
  );
}
