import React from "react";

const LogoErgodoxEz = (props) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 57 36">
        <path
          fill="#fff"
          d="M7.65347 6.21795L0 12.3013V7.69533L7.65347 0.482269"
        />
        <path
          fill="#fff"
          d="M18.9597 0.479172L23.2213 6.99699L17.1333 12.3851L14.4372 9.0827L15.3939 6.21486"
        />
        <path fill="#fff" d="M15.3939 6.21484H7.6535V0.479156H18.9598" />
        <path
          fill="#fff"
          d="M18.9597 0.479172L23.2213 6.99699L17.1333 12.3851L14.4372 9.0827L15.3939 6.21486"
        />
        <path fill="#fff" d="M15.3939 6.21484H7.6535V0.479156H18.9598" />
        <path
          fill="#fff"
          d="M19.7423 21.4231L18.0029 15.3398L26.3521 17.0779"
        />
        <path
          fill="#fff"
          d="M29.7427 17.0779L38.2659 15.2529L36.5265 21.4231"
        />
        <path
          fill="#fff"
          d="M17.1332 12.3851L26.3521 15.166L23.2212 6.997L17.1332 12.3851Z"
        />
        <path
          fill="#fff"
          d="M48.6168 0.479187L56.2703 7.69224V12.2982L48.6168 6.21487"
        />
        <path fill="#fff" d="M37.3107 0.479172H48.6169V6.21486H40.8765" />
        <path fill="#fff" d="M37.3107 0.479172H48.6169V6.21486H40.8765" />
        <path
          fill="#fff"
          d="M40.8765 6.21484L41.5723 9.16959L39.224 12.2981L39.1371 12.385L33.0423 7.00852L37.3107 0.479156"
        />
        <path
          fill="#fff"
          d="M28.0918 32.373L36.0062 24.204L34.5277 29.3314L30.0922 35.2409L28.0918 33.4159"
        />
        <path
          fill="#fff"
          d="M28.0915 33.4159L26.2651 35.2409L21.7426 29.3314L20.2641 24.204L28.0915 32.373"
        />
        <path
          fill="#fff"
          d="M56.2705 12.7327L52.7916 10.1256L49.1389 12.7327H56.2705Z"
        />
        <path
          fill="#fff"
          d="M-3.05176e-05 12.8196L3.47882 10.1256L7.04464 12.8196H-3.05176e-05Z"
        />
        <path
          fill="#fff"
          d="M17.1332 12.3851L26.3521 15.166L23.2212 6.997L17.1332 12.3851Z"
        />
        <path
          fill="#fff"
          d="M29.8311 15.166L33.0491 7.08391V6.997L29.8311 15.166Z"
        />
        <path
          fill="#fff"
          d="M39.1371 12.3852L33.0559 7.01202L29.8311 15.1661L39.1371 12.3852Z"
        />
        <path
          fill="#fff"
          d="M29.8313 15.166L28.7876 17.1648L28.0918 17.2517V30.2874L36.528 21.4231L29.8313 17.0779V15.166Z"
        />
        <path
          fill="#fff"
          d="M27.3961 17.1648L23.2215 6.997L26.3525 15.166V17.0779L19.6557 21.4231C19.6557 21.4231 27.744 29.9397 28.0919 30.2874L28.1789 17.2517L27.3961 17.1648Z"
        />
        <path
          fill="#fff"
          d="M29.8312 15.166L33.0492 6.997H28.0918H23.2214L26.3524 15.166"
        />
        <path
          fill="#fff"
          d="M26.3524 15.166V17.0779L28.0918 17.2517L29.8312 17.0779V15.166"
        />
      </svg>
    </>
  );
};

export default LogoErgodoxEz;
