import React from "react";
import { ThemeProvider } from "styled-components";
import * as StyleVars from './../cssVariables';

const defaultTheme = {
  colors: {
    mainNavbarBg: StyleVars.mainNavbarBg,
    bodyBg: StyleVars.bodyBg,
    navItemColor: StyleVars.navItemColor,
    navItemActiveColor: StyleVars.navItemActiveColor,
    menuIcon: StyleVars.menuIcon,
    font: StyleVars.fontClear,
  }
}

const Theme = ({ theme, children }) => {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};

export default Theme;