import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { opacityTransition } from "../cssVariables";

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-align: center;
  transition: ${opacityTransition};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const zsaLogo = ({
  cy
}) => (
  <div>
    <Logo to="/" data-cy={cy}>
      <img
        src="/assets/logo.svg"
        width={128}
        height={32}
        alt="ZSA Logo"
      />
    </Logo>
  </div>
);

export default zsaLogo;
