import React, { useState } from "react";
import styled from "styled-components";
import * as StyleVars from '../cssVariables';
import ZsaProductsMenu from './ZsaProductsMenu';

const Box = styled.div`
  width: 40px;
  margin-top: 3px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const Inner = styled.span`
  display: block;
  margin-top: -2px;
  top: 2px;
  transition: background-color 0s 0.13s linear;

  &,
  &::before,
  &::after {
    width: 40px;
    height: 4px;
    background-color: ${(props) => props.theme.colors.menuIcon};
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -10px;
  }

  &::after {
    bottom: -10px;
  }

  // animation
  &::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &.active {
    transition-delay: 0.22s;
    background-color: transparent !important;

    &::before {
      top: 0;
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }

    &::after {
      top: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(-45deg);
    }
  }
`;

const Hamburger = styled.button`
  padding: 15px;
  display: inline-block;
  cursor: pointer;
  outline: none;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: transparent;
  -webkit-tap-highlight-color: transparent;
  display: none;

  &:hover {
    opacity: .7;
  }

  &.active {
    &:hover {
      opacity: 0.7;
    }

    ${Inner},
    ${Inner}::before,
    ${Inner}::after {
      background-color: ${(props) => props.theme.colors.menuIcon};
    }
  }
  
  @media(${StyleVars.mediaTablet}) {
    display: block;
  }
`;

const OffCanvasMenuBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0 ,0, 0, .5);
  top: 0;
  right: -100%;
  z-index: 5;

  &.shown {
    right: 0;
  }
`

const MenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  background-color: #141617;
  height: 100%;
  transition: all .3s ease;
`

const MenuIcon = () => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)

  const hideOffCanvasMenu = () => {
    setIsMobileMenuOpened(false)
  }

  return (
    <div>
      <Hamburger
        onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
        className={isMobileMenuOpened ? "active" : ""}
        type="button"
      >
        <Box>
          <Inner className={isMobileMenuOpened ? "active" : ""} />
        </Box>
      </Hamburger>

      <OffCanvasMenuBackdrop className={`${isMobileMenuOpened ? 'shown' : ''}`} onClick={hideOffCanvasMenu}>
        <MenuContainer onClick={(e) => e.stopPropagation()}>
          <ZsaProductsMenu isActive={isMobileMenuOpened} />
        </MenuContainer>
      </OffCanvasMenuBackdrop>
    </div>
  );
};

export default MenuIcon;
