import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll'

import { Text18, Title42 } from './Typography';
import Content from './Content'
import * as StyleVars from './../cssVariables';
import SignupForm from './SignupForm';

const Container = styled.div`
  background-color: #03030B;

  @media(${StyleVars.mediaTablet}) {
    padding-top: 0;
  }
`

const Title = styled(Title42)`
  font-weight: bold;
  text-align: center;
  margin-bottom: 80px;
`

const Text = styled(Text18)`
  margin-bottom: ${props => props.marginBottom || '0'};
`

const ContentText = styled.div`
  width: 55%;
  margin: 0 auto;

  @media(${StyleVars.mediaMobile}){
    width: 100%;
  }
`

const SpacedContent = styled.div`
  width: 70%;
  margin: 0 auto;

  @media(${StyleVars.mediaMobile}){
    width: 100%;
  }
`

const Center = styled.div`
  text-align: center;
`

export default function SignUp(props) {

  return null;
  // return <Container as={Element} name="signup" id="signup">
  //   <Content>
  //     <ContentText>
  //       <Title>
  //         Interested?
  //       </Title>

  //       <Text marginBottom="0">
  //         If you'd like to be notified when an all-metal Habit Stick is available to buy, sign up here:
  //       </Text>
  //     </ContentText>

  //     <SpacedContent>
  //       <SignupForm />

  //       <Center>
  //         <Text>
  //           We'll never send you anything else, just this one notification.
  //         </Text>
  //       </Center>
  //     </SpacedContent>
  //   </Content>

  // </Container>
}
